<template>
  <div class="page endpoint-page">
    <h1>{{ endpoint.title }}</h1>

    <div class="endpoint-bar">
      <div
        :class="`endpoint ${endpoint.method}`"
        @click="goToEndpoint(endpoint)"
      >
        <div :class="`method ${endpoint.method}`">{{ endpoint.method }}</div>
        <p class="ep">{{ endpoint.endpoint }}</p>
      </div>
      <div
        :class="`run ${isLoadingTest ? 'disabled' : ''}`"
        v-if="endpoint.tests"
        @click="runTest"
      >
        Run Test
      </div>
    </div>

    <div v-if="endpoint.tests" class="summary">
      <p class="title">Tests Summary</p>
      <div class="grid-ctn">
        <div
          :class="`item ${
            json[endpoint.endpoint]
              ? json[endpoint.endpoint][index].passed
                ? 'SUCCESS'
                : 'FAILED'
              : 'PENDING'
          }`"
          v-for="(test, index) in endpoint.tests"
          :key="test.tag"
        >
          <div
            :class="`status ${
              json[endpoint.endpoint]
                ? json[endpoint.endpoint][index].passed
                  ? 'SUCCESS'
                  : 'FAILED'
                : `PENDING`
            }`"
          >
            {{
              json[endpoint.endpoint]
                ? json[endpoint.endpoint][index].passed
                  ? "SUCCESS"
                  : "FAILED"
                : `PENDING`
            }}
          </div>
          <p class="label">{{ test.tag }}</p>
        </div>
      </div>
    </div>

    <div v-if="showDetail && endpoint.tests">
      <div v-for="(test, index) in endpoint.tests" :key="index" class="row">
        <div class="side left">
          <p class="title">Request ({{ test.tag }})</p>
          <!-- <pre class="prettyprint lang-js">{{ test.req }}</pre> -->
          <json-viewer theme="json-viewer" :value="test.req"></json-viewer>
          <p class="title">Intended Response</p>
          <!-- <pre class="prettyprint lang-js">{{ test.res }}</pre> -->
          <json-viewer theme="json-viewer" :value="test.res"></json-viewer>
          <div
            :class="`status ${
              json[endpoint.endpoint]
                ? json[endpoint.endpoint][index].passed
                  ? 'SUCCESS'
                  : 'FAILED'
                : `'PENDING'`
            }`"
          >
            {{
              json[endpoint.endpoint]
                ? json[endpoint.endpoint][index].passed
                  ? "SUCCESS"
                  : "FAILED"
                : `'No response'`
            }}
          </div>
        </div>
        <div class="side right">
          <p class="title">Actual Response</p>
          <json-viewer
            theme="json-viewer"
            :expand-depth="1"
            :value="
              json[endpoint.endpoint]
                ? json[endpoint.endpoint][index].res.data
                : `'No response'`
            "
          ></json-viewer>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { LOCAL_STORAGE_KEY } from "@/const";

export default {
  name: "EndpointPage",
  props: {
    endpointProp: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  computed: {
    endpoint() {
      console.log(this.$route.params.key);
      return (
        this.endpointProp ||
        this.$store.getters["main/endpoints/filterEndpointByKey"](
          this.$route.params.key
        )
      );
    },
    isLoadingTest() {
      return this.$store.state.main.tests.isLoadingTest;
    },
    showDetail() {
      return this.$route.meta.showDetail || false;
    },
    isSummary() {
      return this.$route.meta.isSummary || false;
    },
  },
  data() {
    return {
      json: {},
    };
  },
  mounted() {
    this.updateJson();
  },
  methods: {
    async runTest() {
      if (this.isLoadingTest) {
        return;
      }

      const respArr = await this.$store.dispatch("main/tests/testEndpoint", {
        endpoint: this.endpoint,
      });

      console.log("response", respArr);

      let json = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
      console.log(json);

      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          ...json,
          [this.endpoint.endpoint]: this.endpoint.tests.map((e, i) => {
            console.log(
              Object.keys(e.res).map((a) =>
                e.res[a].charAt(0) == "!"
                  ? e.res[a].substring(1) != respArr[i].data[a]
                  : e.res[a] == respArr[i].data[a]
              )
            );
            return {
              tag: e.tag,
              passed: Object.keys(e.res)
                .map((a) =>
                  e.res[a].charAt(0) == "!"
                    ? e.res[a].substring(1) != respArr[i].data[a]
                    : e.res[a] == respArr[i].data[a]
                )
                .every((e) => e == true),
              res: respArr[i],
            };
          }),
          date: Date.now,
        })
      );

      this.updateJson();
    },
    updateJson() {
      this.json = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    },
    goToEndpoint(endpoint) {
      if (!this.isSummary) {
        return;
      }

      this.$router.push({
        path: `/${this.$route.params.version}/endpoints/${endpoint.endpoint}`,
      });
    },
  },
};
</script>
<style scoped>
</style>
