export const ENUMS = {
  keyIdTypeEnum: {
    "0": "Card Number",
    "1": "Account Number"
  },
  currencyEnum: {
    "0003": "USD - United States Dollar"
  },
  flagEnum: {
    "Y": "Uploaded",
    "N": "Not Uploaded"
  },
}
