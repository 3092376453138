import { ENUMS } from "./enums";

export const DEFAULT_ATTRIBUTE = {
  key: "",
  description: "Attribute missing!",
  value: "",
  type: "Attribute missing!",
}

export const ATTRIBUTES = [
  {
    key: "custId",
    description: "15 digit Customer ID (unique to every user)",
    value: "000000000000034",
    wrongValue: "000000000000003",
    type: "String",
  },
  {
    key: "extCardId",
    description: "Card Mapping ID (unique to every card)",
    value: "a6d4e043-72fe-485a-91fe-3d3badf52cff",
    wrongValue: "bdfb8456-aada-4625-a8a8-600a0d3bdb3g",
    type: "String",
  },
  {
    key: "mobile",
    description: "Phone Number",
    value: "855-92327127",
    wrongValue: "855-987987987",
    type: "String",
  },
  {
    key: "pidType",
    description: "Type of Document",
    value: "1",
    wrongValue: "3",
    enum: {
      1: "National ID",
      3: "Passport",
    },
    type: "String",
  },
  {
    key: "pidNoEnc",
    description: "Document Number",
    value: "TiAQyKzvzL46uJboWchYkUklbuwQdMV_e5X7P5cL1lOCliSbq3F3t907Dax0_FOa7031m2jjlzyVIpkL82nfMMZwkmpKzuA_DbNXsEjipoSvZWUyyVq3v9d0QRJLLSWdtXQ-2ywu9twjnpA9WL4mAQLflfl5ob76J2R4H8oWR32iJo8qYQRx_aow7w9UmEOS43947dvTlb22fiRztW7JXSnhRF99C4CyFB0MqJuVSiHfxpLgbYn-aiF5R3fe2DOWkRz5Sm7C-TZIEyDtYYE7vQ2mxCh0g8BBKT91AwDmtzxYGyDkkNfodkwdcDNj24U0iPCPS-TVkG1uVs6kxFjdag==",
    wrongValue: "UE9AZNxGCrGxbQs5CWT6p_X4NE1ndodGa-0dISICKZxzJ2RveOPksvLW1XkQQtfic4yHRTRl4c9bPtf-KGNxH_mANNWHIKK3ozMqUt6tzj6KN7QdH8C8sdLavpyyCUq-Nz33mCcT5cGK6_v2IaPeo8Pve6S44BHJ3mDLWx0i6_8ucw4CCt-xxCqyX2FiVe3F_6ed0N1lqVqO6p3SpPNmXJU7S0xIXXCd20wCxbyTiKjk7zZ2QLQiUW8YYeRhPsyAYSIWPad9reBHjE6tIXB_NktAMJl24wx2Ii7G5uNYISD8BKjcuYMwR_S08cvjz7WsDYvk3qOWretk2FQR86Ds2g==",
    type: "String",
    encrypted: true,
  },
  {
    key: "brandId",
    description: "Card Brand (4 digits)",
    value: "0003",
    enum: {
      "0003": "Bongloy Physical Card",
      "0005": "Bongloy Virtual Card",
    },
    type: "String",
  },
  {
    key: "nameEnc",
    description: "Full Name",
    value: "Ah8X5tgJXdIekE8FaNHVN7ImsJYbYSAlPaDvs_6barKSaEAwsZfT98SfQHvZ9h-EZe8jzBhpIbWRV2lBT5pHEJzM8UtZFn0Dijb8sK__urQGemJ5OkWbxhf6dnMcq2MAe7fsbWls7JNTdUdlt-9_s4MgBpfgKGmVCUQ0bZabTV0VNf43LicNDRwRBCNnCTHLrjLXCmuq-wyawMyTvm6Kb1W0hiPScKyqg-1wLPqpZNTn-z4L6YjU410qfFcSCEQUlCFreDOMLliUUI7udyKNyjic2vVrt4ZfI9J4pBZVPPFhSbsY5PTwKeYwuygxEfDgUbwndpaZlBWi7RoEJnjm0g==",
    wrongValue: "eWMyPVHAg0q0XOsOo5euDBSH1_1xTB2b3rp0IsBbSI0oPoMJcgfrmDVf_kbIja56eq6NaVZXWVd0G-U2wBVK7P-ihC0GmMhmpfYtCXhi9Z0eEPMq3qBsd5PfSP0FoMgZdy7adOv_1DSIhV6EQf8FHMEcoNBoBnAU0gsiAFYdXeqokoc5jvMJRyI4L2ZFCvKfm7U4xpHhpjzM7yuZAM71VfMtb8sJgpnbDZZqn_NXA3bbh6MZc1Nk0JHQ4y1gz_kza4pYO3EAvYIhGxfVkhkglQzVtJckoeAinZLmMvlVzyogLPTLGoulqNQzRkInQd1I_ofz9_Gze1ttXk5SPtVuhw==",
    encrypted: true,
    type: "String",
  },
  {
    key: "keyId",
    description: "Card/Account Number",
    value: "000000187",
    wrong: "000000000",
    type: "String",
  },
  {
    key: "addr",
    description: "Address",
    value: "",
    type: "String",
  },
  {
    key: "nationality",
    description: "Nationality",
    value: "Cambodia",
    type: "String",
  },
  {
    key: "email",
    description: "Email Address",
    value: "mail@bongloy.com",
    type: "String",
  },
  {
    key: "addr",
    description: "Address",
    value: "",
    type: "String",
  },
  {
    key: "cardIdEnc",
    description: "Card Number",
    encrypted: true,
    value:
      "OGW2IOdpG/Gx2+Z7WVlWRYOENkpwskrZVJyAV1UxIGLS1ENm68+mCjAvQC5sAlB5aVmpMgCmaSIJM2TJVOM1MxGwX5+shGVUIS9+5JGuu4rFQ4d8zCCs2NxZHd7zZ6igKIhpCLS0s6vSijgwNcbZ0gtsbSfCvdzSGDR7TCcFgHFhZTHdOpQGuULRK+oY4MppJJWp9TI3hRbhkfJSnKcdi+cO4N970Sy+6N6CcBLYhrW4Nd8GLoq2Zgvcn/iZjXEMghHBgz91JvV/G3mmMjvMXZTSryCrRvENpX7FUBkk2lrk9H7vp8WjOuTlsxbvuu7U78iS6oP7Ahuar2I+I1b3jA==",
    type: "String",
  },
  {
    key: "newPinEnc",
    description: "New ATM Pin for Card (6 digits)",
    value: "RcMKDQe9vijerZMwMb4ozt0jlfBBsXWNvKld9159rVHRcRx0LQRmx6nrPpDtgpnu4Dw/5O4PH0nCZF+BDAjLjvbTmL2p9Tf5R0hxu9SGd1LcWOxuGTC6akthXR/2QAAqO5jPpomcS3RQTl/yEBWQkqrTyskJenyy9ogBAw2FUfui9tK/1Y+My6w8dAE4YIiuZ6b5bYhrnixqY3wVooQBJkneOIOV1T4ZqGSpL2Z4UpDi2eqPphPK2T8Xuth/keQUhGxcPrtqF2ui0mWEoqoHO2k5OXi7XWKBCRO+mODrrcDO+5HVKao+FkduPgr8AFtJAcQBOeGTbgYHDOlalt8HNg==",
    encrypted: true,
    type: "String",
  },
  {
    key: "email",
    description: "Email Address",
    value: "",
    type: "String",
  },
  {
    key: "cardId4",
    description: "Last 4 digits of Card's Number",
    value: "6789",
    type: "String",
  },
  {
    key: "cardBrhId",
    description: "Card's Institution's ID",
    value: "",
    type: "String",
  },
  {
    key: "cardBrhNm",
    description: " Card's Institution's Name",
    value: "",
    type: "String",
  },
  {
    key: "consumeSingleMax",
    description: "Limit Amount of Single Purchase",
    value: "1000",
    type: "String",
  },
  {
    key: "consumeDayMaxNum",
    description: "Limit Daily Number of Purchase",
    value: "5",
    type: "String",
  },
  {
    key: "consumeDayMax",
    description: "Limit Daily Purchase Amount",
    value: "5000",
    wrongValue: "4000",
    type: "String",
  },
  {
    key: "withdrawDayMax",
    description: "Limit Daily Wthdrawal Amount",
    value: "5000",
    wrongValue: "4000",
    type: "String",
  },
  {
    key: "withdrawDayMaxNum",
    description: "Limit Daily Number of Withdrawal",
    value: "5",
    type: "String",
  },
  {
    key: "withdrawSingleMax",
    description: "Limit Amount of Single Withdrawal",
    value: "1000",
    type: "String",
  },
  {
    key: "tel",
    description: "Phone Number",
    value: "855-23567567",
    type: "String",
  },
  {
    key: "cvn2",
    description: "Card's CVV (3 digits)",
    value: "789",
    type: "String",
  },
  {
    key: "endDt",
    description: "End date (Format: yyMM)",
    value: "2503",
    type: "String",
  },
  {
    key: "keyIdIN",
    description: "Receiver's Account/Card Number",
    value: "123 123 123",
    type: "String",
  },
  {
    key: "keyIdINType",
    description: "Receiving Media",
    value: "1",
    enum: ENUMS.keyIdTypeEnum,
    type: "String",
  },
  {
    key: "keyIdOut",
    description: "Sender's Account/Card Number",
    value: "234 234 234",
    type: "String",
  },
  {
    key: "note",
    description: "Remarks",
    value: "",
    type: "String",
  },
  {
    key: "pinEnc",
    description: "Card ATM PIN (6 digits)",
    value: "RcMKDQe9vijerZMwMb4ozt0jlfBBsXWNvKld9159rVHRcRx0LQRmx6nrPpDtgpnu4Dw/5O4PH0nCZF+BDAjLjvbTmL2p9Tf5R0hxu9SGd1LcWOxuGTC6akthXR/2QAAqO5jPpomcS3RQTl/yEBWQkqrTyskJenyy9ogBAw2FUfui9tK/1Y+My6w8dAE4YIiuZ6b5bYhrnixqY3wVooQBJkneOIOV1T4ZqGSpL2Z4UpDi2eqPphPK2T8Xuth/keQUhGxcPrtqF2ui0mWEoqoHO2k5OXi7XWKBCRO+mODrrcDO+5HVKao+FkduPgr8AFtJAcQBOeGTbgYHDOlalt8HNg==",
    encrypted: true,
    type: "String",
  },
  {
    key: "prdtNoOut",
    description: "Sender's Account Currency Type",
    value: "0003",
    enum: ENUMS.currencyEnum,
    type: "String",
  },
  {
    key: "txnAt",
    description: "Transfer Amount (in USD cents)",
    value: "",
    type: "String",
  },
  {
    key: "acctIdIn",
    description: "Receiver's Account Number",
    value: "123 123 123",
    type: "String",
  },
  {
    key: "acctIdOut",
    description: "Sender's Account Number",
    value: "123 123 123",
    type: "String",
  },
  {
    key: "acctNmIn",
    description: "Receiver's Full Name",
    value: "",
    type: "String",
  },
  {
    key: "acctNmOut",
    description: "Sender's Full Name",
    value: "",
    type: "String",
  },
  {
    key: "cardBrhIdIN",
    description: "Receiver's Card's Institution's ID",
    value: "",
    type: "String",
  },
  {
    key: "cardBrhIdOut",
    description: "Sender's Card's Institution's ID",
    value: "",
    type: "String",
  },
  {
    key: "cardBrhNmIN",
    description: "Receiver's Card's Institution's Name",
    value: "",
    type: "String",
  },
  {
    key: "cardBrhNmOut",
    description: "Sender's Card's Institution's Name",
    value: "",
    type: "String",
  },
  {
    key: "cardIdIN4",
    description: "Last 4 Digits of Reciever's Card",
    value: "",
    type: "String",
  },
  {
    key: "cardIdOut4",
    description: "Last 4 Digits of Sender's Card",
    value: "",
    type: "String",
  },
  {
    key: "currAvailAtOut",
    description: "Sender's Available Balance After Transfer",
    value: "",
    type: "String",
  },
  {
    key: "currBalAtOut",
    description: "Sender's Balance After Transfer",
    value: "",
    type: "String",
  },
  {
    key: "endDtOut",
    description: "Sender's Card Expiry",
    value: "",
    type: "String",
  },
  {
    key: "intTxnRefId",
    description: "Transaction Reference Number",
    value: "",
    type: "String",
  },
  {
    key: "intTxnSeqId",
    description: "Transaction Sequence Number",
    value: "",
    type: "String",
  },
  {
    key: "lockAtOut",
    description: "Sender's Frozen Amount",
    value: "",
    type: "String",
  },
  {
    key: "txnDt",
    description: "Transaction Date",
    value: "",
    type: "String",
  },
  {
    key: "txnTm",
    description: "Transaction Time",
    value: "",
    type: "String",
  },
  {
    key: "acctId",
    description: "Account Number",
    value: "123 123 123",
    type: "String",
  },
  {
    key: "acctSta",
    description: "Account Status",
    value: "0",
    enum: {
      "0": "Normal",
      "2": "Frozen",
      "4": "Closed"
    },
    type: "String",
  },
  {
    key: "cardSta",
    description: "Card Status",
    value: "",
    enum: {
      "0": "Normal",
      "1": "Frozen",
      "3": "Closed",
      "9": "Unactivated"
    },
    type: "String",
  },
  {
    key: "currAvailAt",
    description: "Current Available balance",
    value: "",
    type: "String",
  },
  {
    key: "currBalAt",
    description: "Current Balance",
    value: "",
    type: "String",
  },
  {
    key: "lastTxnDt",
    description: "Last Transaction Date",
    value: "",
    type: "String",
  },
  {
    key: "lockAt",
    description: "Frozen Balance",
    value: "",
    type: "String",
  },
  {
    key: "prdtNo",
    description: "Account Currency Type",
    enum: ENUMS.currencyEnum,
    value: "0003",
    type: "String",
  },
  {
    key: "startDt",
    description: "Card Issued Dat",
    value: "",
    type: "String",
  },
  {
    key: "pageNum",
    description: "Page Number (Pagination)",
    value: "1",
    type: "String",
  },
  {
    key: "pageSize",
    description: "Page Size (Pagination)",
    value: "20",
    type: "String",
  },
  {
    key: "txnCode",
    description: "Transaction code",
    value: "",
    type: "String",
  },
  {
    key: "availAt",
    description: "Available Balance (in USD Cents)",
    value: "10000",
    type: "String",
  },
  {
    key: "cardId",
    description: "Card number",
    value: "2345 2345 2345 2345",
    type: "String",
  },
  {
    key: "cardIdIN",
    description: "Receiver's card number",
    value: "2345 2345 2345 2345",
    type: "String",
  },
  {
    key: "cardIdOut",
    description: "Sender's card number",
    value: "2345 2345 2345 2345",
    type: "String",
  },
  {
    key: "cashflowType",
    description: "Type of Cashflow",
    value: "1",
    enum: {
      "1": "Money In",
      "2": "Money Out"
    },
    type: "String",
  },
  {
    key: "fee",
    description: "Fee",
    value: "",
    type: "String",
  },
  {
    key: "txnCd",
    description: "Transaction Code",
    value: "",
    type: "String",
  },
  {
    key: "txnDt",
    description: "Transaction Date",
    value: "",
    type: "String",
  },
  {
    key: "txnRefId",
    description: "Transaction Reference Number",
    value: "",
    type: "String",
  },
  {
    key: "txnSeqId",
    description: "Transaction Sequence Number",
    value: "",
    type: "String",
  },
  {
    key: "txnStaCd",
    description: "Transaction Status Code",
    value: "",
    enum: {
      "2": "Success",
      "3": "Refunded",
      "4": "Canceled"
    },
    type: "String",
  },
  {
    key: "txnType",
    description: "Transaction Type",
    value: "",
    type: "String",
  },

  {
    key: "zip",
    description: "Zip Code (5 digits)",
    value: "12405",
    type: "String",
  },
  {
    key: "virtuAppId",
    description: "Virtual Card Application ID (5 digits)",
    value: "10",
    wrongValue: "999999",
    type: "String",
  },
  {
    key: "vouId",
    description: "Virtual Card Number (16 digits)",
    value: "2345 2345 2345 2345",
    type: "String",
  },
  {
    key: "physicalAppId",
    description: "Physical Card Application ID (5 digits)",
    value: "10",
    wrongValue: "999999",
    type: "String",
  },
  {
    key: "headPicUrlFlag",
    description: "Uploaded Head Photo Flag",
    value: "Y",
    enum: ENUMS.flagEnum,
    type: "String",
  },
  {
    key: "pidNoUrlFlag",
    description: "Uploaded Document Photo Flag",
    value: "Y",
    enum: ENUMS.flagEnum,
    type: "String",
  },
  {
    key: "sta",
    description: "虚拟卡审核状态[01.未审核 06.审核通过 07.审核拒绝 08.审核通过, 激活Sucess]",
    value: "",
    type: "String",
  },
  {
    key: "locNat",
    description: "Nationality",
    value: "",
    type: "String",
  },
  {
    key: "smsSerialId",
    description: "发送验证码流水号",
    value: "",
    type: "String",
  },
  {
    key: "smsSerialNo",
    description: "短信验证码",
    value: "",
    type: "String",
  },
  {
    key: "smsType",
    description: "短信Type",
    value: "",
    type: "String",
  },
  {
    key: "cardBatchQueryInfoModelList",
    description: "List of Cards",
    value: "",
    type: "Array",
    attributes: [
      "acctId",
      "acctSta",
      "cardId4",
      "cardSta",
      "currAvailAt",
      "currBalAt",
      "endDt",
      "extCardId",
      "lastTxnDt",
      "lockAt",
      "prdtNo",
      "startDt",
    ]
  },
  {
    key: "idPhotos",
    description: "Photo of Documents",
    value: "",
    type: "Array",
    attributes: [
      "base64Data",
      "originalFileName"
    ]
  },
  {
    key: "headPhotos",
    description: "Head Photo of User",
    value: "",
    type: "Array",
    attributes: [
      "base64Data",
      "originalFileName"
    ]
  },
  {
    key: "base64Data",
    description: "Base64 Data of Image",
    value: "",
    type: "String"
  },
  {
    key: "originalFileName",
    description: "Original File Name of Image",
    value: "",
    type: "String"
  }
];
