import endpoints from "./endpoints"
import tests from "./tests"

const mainStore = {
  namespaced: true,
  modules: {
    endpoints,
    tests
  }
}

export default mainStore
