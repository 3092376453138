<template>
  <div class="page endpoint-summary-page">
    <h1>Summary</h1>

    <EndpointPage
      :endpointProp="endpoint"
      v-for="endpoint in endpoints"
      :key="endpoint.endpoint"
    />


    <!-- <div v-for="endpoint in endpoints" :key="endpoint.endpoint" class="item">
      <p class="title">{{ endpoint.title }}</p>
      <div :class="`endpoint ${endpoint.method}`">
        <div :class="`method ${endpoint.method}`">{{ endpoint.method }}</div>
        <p class="ep">{{ endpoint.endpoint }}</p>
      </div>

      <div v-if="endpoint.tests" class="result-ctn">
        <div
          v-for="(test, index) in endpoint.tests"
          :key="index"
          class="result pending"
        >
          <p class="label">{{ test.tag }}</p>

          <pre class="prettyprint lang-js">{{ test.req }}</pre>
        </div>
      </div>
    </div> -->
  </div>
</template>


<script>
import EndpointPage from "./EndpointPage.vue";

export default {
  name: "EndpointSummaryPage",
  computed: {
    endpoints() {
      return this.$store.getters["main/endpoints/filterEndpoints"]();
    },
  },
  components: {
    EndpointPage,
  },
  mounted(){
  }
};
</script>
<style scoped>
</style>
