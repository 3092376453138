import http from "@/services/http"

const SET_LOADING_TEST = "setLoadingTest"

const tests = {
  namespaced: true,
  state: {
    isLoadingTest: false
  },
  mutations: {
    [SET_LOADING_TEST](state, payload) {
      state.isLoadingTest = payload
    }
  },
  actions: {
    async testEndpoint(context, payload) {
      context.commit(SET_LOADING_TEST, true)
      const key = context.rootState.route.params.key;
      const version = context.rootState.route.params.version;
      let array;
      if (payload.endpoint.auth) {

        const token = await context.dispatch("getToken");
        console.log('token', token)
        console.log('test', key, version)
        array = await payload.endpoint.tests.map(async (e) => {
          return http.post(
            `${payload.endpoint.endpoint}`,
            {
              ...e.req,
              pin_code_enc: "rxFgNQ4MscEN/i9ZIw1s0MdqcEgyUOwQLF7Kzi3uZzyVowvEl7IwKfH/Tz9mQQ/PWKYioYRuNnq/yRjTbTGbN6+kgAAbKh4QlNANXMdnh61nxSImnJovgvjBkWhm631+qnQOB7ViXMTMJq0ih9tv1LErvTv0BacyHjIGeQNQoIQ="
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )
        })
      } else {
        array = await payload.endpoint.tests.map(async (e) => {
          return http.post(
            `${payload.endpoint.endpoint}`,
            {
              ...e.req,
            },
          )
        })
      }

      const temp = await Promise.all(array)
      context.commit(SET_LOADING_TEST, false)
      return temp
    },
    async getToken(context, payload) {
      context.commit(SET_LOADING_TEST, true)
      const response = await http.post(
        `login`,
        {
          cust_id: "000000000000034",
          pin_code_enc: "rxFgNQ4MscEN/i9ZIw1s0MdqcEgyUOwQLF7Kzi3uZzyVowvEl7IwKfH/Tz9mQQ/PWKYioYRuNnq/yRjTbTGbN6+kgAAbKh4QlNANXMdnh61nxSImnJovgvjBkWhm631+qnQOB7ViXMTMJq0ih9tv1LErvTv0BacyHjIGeQNQoIQ="
        }
      )

      return response.data.token
    }
  },
  getters: {

  }
}

export default tests
