import { HTTP_METHODS } from "@/const";

export const ENDPOINTS = [
  {
    title: "Change Cardholder Information Application",
    endpoint: "custInfoModifyAppli",
    method: HTTP_METHODS.POST,
    attributes: ["addr", "custId", "email", "headPhotos", "idPhotos", "nationality", "smsSerialId", "smsSerialNo", "smsType", "tel", "zip"],
    respAttributes: [
      "appId",
    ],
  },
  {
    title: "Query Requesting Virtual Card Progress",
    endpoint: "virtualAppliDetail",
    method: HTTP_METHODS.POST,
    attributes: ["virtuAppId"],
    respAttributes: [
      "addr",
      "email",
      "headPicUrlFlag",
      "mobile",
      "nameEnc",
      "nationality",
      "note",
      "pidNoEnc",
      "pidNoUrlFlag",
      "pidType",
      "sta",
      "tel",
      "virtuAppId",
      "zip",
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          virtuAppId: ""
        },
        res: {
          respCode: "00000"
        },
      },
      {
        tag: "Wrong virtuAppId",
        req: {
          virtuAppId: "wrong"
        },
        res: {
          respCode: "01310"
        },
      },
      {
        tag: "No virtuAppId",
        req: {
        },
        res: {
          respCode: "10101"
        },
      }
    ]
  },
  {
    title: "Query Virtual Card Sensitive Data",
    endpoint: "virtualCardSensQuery",
    method: HTTP_METHODS.POST,
    attributes: ["custId", "extCardId"],
    respAttributes: [
      "brandId",
      "cardIdEnc",
      "cardSta",
      "cvn2",
      "endDt",
      "startDt",
    ],
  },
  {
    title: "Request Additional Virtual Card",
    endpoint: "virtualCardOpenForOld",
    method: HTTP_METHODS.POST,
    attributes: ["brandId", "custId", "mobile", "nameEnc", "pidNoEnc", "pidType"],
    respAttributes: [
      "cardId4",
      "extCardId",
    ],
    auth: true,
    tests: [
      {
        tag: "All Correct",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Non Virtual Brand",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01056"
        }
      },
      {
        tag: "Wrong Brand",
        req: {
          brandId: "9999",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01050"
        }
      },
      {
        tag: "Wrong CustId",
        req: {
          brandId: "0005",
          custId: "wrong",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01300"
        }
      },
      {
        tag: "No CustId",
        req: {
          brandId: "0005",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong Mobile",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "wrong",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01520"
        }
      },
      {
        tag: "No Mobile",
        req: {
          brandId: "0005",
          custId: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong Name",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "",
          nameEnc: "wrong",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01519"
        }
      },
      {
        tag: "No Name",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong Pid",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "wrong",
          pidType: ""
        },
        res: {
          respCode: "01521"
        }
      },
      {
        tag: "No Pid",
        req: {
          brandId: "10101",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong PidType",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: "wrong"
        },
        res: {
          respCode: "01521"
        }
      },
      {
        tag: "No PidType",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "wrong",
          nameEnc: "",
          pidNoEnc: "",
        },
        res: {
          respCode: "10101"
        }
      },
    ]
  },
  {
    title: "Query Requesting Physical Card Progress",
    endpoint: "physicalCardAppliDetail",
    method: HTTP_METHODS.POST,
    attributes: ["physicalAppId"],
    respAttributes: [
      "addr",
      "cardId4",
      "email",
      "headPicUrlFlag",
      "mobile",
      "nameEnc",
      "nationality",
      "note",
      "pidNoEnc",
      "pidNoUrlFlag",
      "pidType",
      "sta",
      "tel",
      "virtuAppId",
      "zip",
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          physicalAppId: ""
        },
        res: {
          respCode: "00000"
        },
      },
      {
        tag: "Wrong physicalAppId",
        req: {
          physicalAppId: "wrong"
        },
        res: {
          respCode: "01310"
        },
      },
      {
        tag: "No physicalAppId",
        req: {
        },
        res: {
          respCode: "10101"
        },
      }
    ]
  },
  {
    title: "Request Virtual Card (New Cardholder)",
    endpoint: "virtualCardOpen",
    method: HTTP_METHODS.POST,
    attributes: ["addr", "brandId", "email", "headPhotos", "idPhotos", "mobile", "nameEnc", "nationality", "pidNoEnc", "pidType", "tel", "zip"],
    respAttributes: [
      "virtuAppId",
      "vouId",
    ],
  },
  {
    title: "Query Batch Transaction Details",
    endpoint: "queryOrderDetail",
    method: HTTP_METHODS.POST,
    attributes: ["acctId", "custId", "endDt", "extCardId", "pageNum", "pageSize", "startDt", "txnCode"],
    respAttributes: [
      "acctId",
      "acctIdIn",
      "acctIdOut",
      "acctNmIn",
      "acctNmOut",
      "availAt",
      "cardBrhNmIN",
      "cardBrhNmOut",
      "cardId",
      "cardIdIN",
      "cardIdOut",
      "cashflowType",
      "custId",
      "extCardId",
      "fee",
      "note",
      "txnAt",
      "txnCd",
      "txnDt",
      "txnRefId",
      "txnSeqId",
      "txnStaCd",
      "txnTm",
      "txnType",
    ],
  },
  {
    title: "Query Batch Account Information",
    endpoint: "queryAccount",
    method: HTTP_METHODS.POST,
    attributes: ["custId"],
    respAttributes: [
      "acctId",
      "acctSta",
      "currAvailAt",
      "currBalAt",
      "lastTxnDt",
      "lockAt",
      "prdtNo",
    ],
  },
  {
    title: "Query Batch Card Information",
    endpoint: "cardBatchQueryInfo",
    method: HTTP_METHODS.POST,
    attributes: ["custId"],
    respAttributes: [
      "cardBatchQueryInfoModelList",
      "custId"
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          custId: "",
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Wrong CustId",
        req: {
          custId: "wrong",
        },
        res: {
          respCode: "01300"
        }
      },
      {
        tag: "Missing CustId",
        req: {
        },
        res: {
          respCode: "10101"
        }
      },
    ]
  },
  {
    title: "Change Card PIN",
    endpoint: "cardChgPwd",
    method: HTTP_METHODS.POST,
    attributes: ["custId", "extCardId", "newPinEnc", "pinEnc"],
    respAttributes: [
      "endDt",
    ],
  },
  {
    title: "Reset Card PIN",
    endpoint: "resetCardPwd",
    method: HTTP_METHODS.POST,
    attributes: ["custId", "extCardId", "newPinEnc"],
    respAttributes: [
      "endDt",
    ],
  },
  {
    title: "Freeze Card",
    endpoint: "applyFreezeCard",
    method: HTTP_METHODS.POST,
    attributes: ["custId", "extCardId"],
    respAttributes: [],
    tests: [
      {
        tag: "All Correct",
        req: {
          custId: "",
          extCardId: "",
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "CustId Incorrect",
        req: {
          custId: "wrong",
          extCardId: "",
        },
        res: {
          respCode: "10304"
        }
      },
      {
        tag: "ExtCardId Incorrect",
        req: {
          custId: "",
          extCardId: "wrong",
        },
        res: {
          respCode: "10303"
        }
      },
      {
        tag: "CustId Missing",
        req: {
          extCardId: "",
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "ExtCardId Missing",
        req: {
          custId: ""
        },
        res: {
          respCode: "10101"
        }
      },
    ]
  },
  {
    title: "Unfreeze Card",
    endpoint: "applyUnfreezeCard",
    method: HTTP_METHODS.POST,
    attributes: ["extCardId", "custId"],
    respAttributes: [],
    tests: [
      {
        tag: "All Correct",
        req: {
          custId: "",
          extCardId: "",
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "CustId Incorrect",
        req: {
          custId: "wrong",
          extCardId: "",
        },
        res: {
          respCode: "10304"
        }
      },
      {
        tag: "ExtCardId Incorrect",
        req: {
          custId: "",
          extCardId: "wrong",
        },
        res: {
          respCode: "10303"
        }
      },
      {
        tag: "CustId Missing",
        req: {
          extCardId: "",
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "ExtCardId Missing",
        req: {
          custId: ""
        },
        res: {
          respCode: "10101"
        }
      },
    ]
  },
  {
    title: "Check Customer Information by Phone Number",
    endpoint: "custInfoQueryByCell",
    method: HTTP_METHODS.POST,
    attributes: ["mobile"],
    respAttributes: [
      "addr",
      "custId",
      "email",
      "locNat",
      "mobile",
      "nameEnc",
      "dob",
      "khNameEnc",
      "locNat",
      "memberEnrolledDate",
      "memberId",
      "organizationName"
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          mobile: "",
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Missing Mobile",
        req: {
        },
        res: {
          respCode: "!00000"
        }
      },
      {
        tag: "Incorrect Mobile",
        req: {
          mobile: "wrong",
        },
        res: {
          respCode: "!00000"
        }
      }
    ]
  },
  {
    title: "Check Customer Information by Document Number",
    endpoint: "custInfoQueryByPid",
    method: HTTP_METHODS.POST,
    attributes: ["pidType", "pidNoEnc"],
    respAttributes: [
      "addr",
      "custId",
      "email",
      "locNat",
      "mobile",
      "nameEnc",
      "dob",
      "khNameEnc",
      "locNat",
      "memberEnrolledDate",
      "memberId",
      "organizationName"
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          pidType: "",
          pidNoEnc: ""
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Wrong Type",
        req: {
          pidType: "wrong",
          pidNoEnc: ""
        },
        res: {
          respCode: "!00000"
        }
      },
      {
        tag: "Missing Type",
        req: {
          pidNoEnc: ""
        },
        res: {
          respCode: "!00000"
        }
      },
      {
        tag: "Wrong Pid",
        req: {
          pidType: "",
          pidNoEnc: "wrong"
        },
        res: {
          respCode: "!00000"
        }
      },
      {
        tag: "Missing Pid",
        req: {
          pidType: "",
        },
        res: {
          respCode: "!00000"
        }
      },
    ]
  },
  {
    title: "Check Customer Name by KeyId",
    endpoint: "custInfoQuery",
    method: HTTP_METHODS.POST,
    attributes: ["keyId"],
    respAttributes: ["nameEnc"],
    tests: [
      {
        tag: "Account Correct",
        req: {
          keyId: ""
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Card Correct",
        req: {
          keyId: "6263014516000015"
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Account Incorrect",
        req: {
          keyId: "wrong"
        },
        res: {
          respCode: "!00000"
        }
      },
      {
        tag: "Card Incorrect",
        req: {
          keyId: "5063014516000015"
        },
        res: {
          respCode: "!00000"
        }
      },
      {
        tag: "Missing Input",
        req: {
        },
        res: {
          respCode: "!00000"
        }
      },
    ]
  },
  {
    title: "Cardholder Verification",
    endpoint: "cardUserVerify",
    method: HTTP_METHODS.POST,
    attributes: ["keyId", "mobile", "pidNoEnc", "pidType", "nameEnc"],
    optionalAttri: ["pidNoEnc", "pidType", "nameEnc"],
    respAttributes: [
      "addr",
      "custId",
      "email",
      "headPhotos",
      "idPhotos",
      "mobile",
      "nameEnc",
      "nationality",
      "tel",
    ],
  },
  {
    title: "Query Card Limitation",
    endpoint: "queryCardLimit",
    method: HTTP_METHODS.POST,
    attributes: ["custId", "extCardId"],
    respAttributes: [
      "cardId4",
      "cardBrhId",
      "cardBrhNm",
      "consumeDayMax",
      "consumeDayMaxNum",
      "consumeSingleMax",
      "withdrawDayMax",
      "withdrawDayMaxNum",
      "withdrawSingleMax",
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          custId: "",
          extCardId: ""
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Mismatch CustId",
        req: {
          custId: "wrong",
          extCardId: ""
        },
        res: {
          respCode: "10304"
        }
      },
      {
        tag: "No CustId",
        req: {
          extCardId: ""
        },
        res: {
          respCode: "10101"
        }
      }
    ]
  },
  {
    title: "Set Card Limitation",
    endpoint: "setCardLimit",
    method: HTTP_METHODS.POST,
    attributes: ["custId", "extCardId", "consumeDayMax", "consumeSingleMax", "consumeDayMaxNum", "withdrawDayMax", "withdrawDayMaxNum", "withdrawSingleMax"],
    respAttributes: [
      "cardId4",
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          custId: "",
          extCardId: "",
          consumeDayMax: "",
          consumeDayMaxNum: "",
          consumeSingleMax: "",
          withdrawDayMax: "",
          withdrawDayMaxNum: "",
          withdrawSingleMax: "",
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Wrong CustId",
        req: {
          custId: "wrong",
          extCardId: "",
          consumeDayMax: "",
          consumeDayMaxNum: "",
          consumeSingleMax: "",
          withdrawDayMax: "",
          withdrawDayMaxNum: "",
          withdrawSingleMax: "",
        },
        res: {
          respCode: "10304"
        }
      },
      {
        tag: "Wrong ExtCardId",
        req: {
          custId: "",
          extCardId: "wrong",
          consumeDayMax: "",
          consumeDayMaxNum: "",
          consumeSingleMax: "",
          withdrawDayMax: "",
          withdrawDayMaxNum: "",
          withdrawSingleMax: "",
        },
        res: {
          respCode: "10303"
        }
      },
      // {
      //   tag: "Wrong Consume Day",
      //   req: {
      //     custId: "",
      //     extCardId: "",
      //     consumeDayMax: "wrong",
      //     consumeDayMaxNum: "",
      //     consumeSingleMax: "",
      //     withdrawDayMax: "",
      //     withdrawDayMaxNum: "",
      //     withdrawSingleMax: "",
      //   },
      //   res: {
      //     respCode: "!00000"
      //   }
      // },
      // {
      //   tag: "Wrong Consume Day",
      //   req: {
      //     custId: "",
      //     extCardId: "",
      //     consumeDayMax: "",
      //     consumeDayMaxNum: "",
      //     consumeSingleMax: "",
      //     withdrawDayMax: "wrong",
      //     withdrawDayMaxNum: "",
      //     withdrawSingleMax: "",
      //   },
      //   res: {
      //     respCode: "!00000"
      //   }
      // }
    ]
  },
  {
    title: "Activate Physical Card",
    endpoint: "cardInfoActive",
    method: HTTP_METHODS.POST,
    attributes: ["nameEnc", "pidNoEnc", "cardIdEnc", "custId", "cvn2", "pidType", "newPinEnc", "endDt"],
    respAttributes: [
      "brandId",
      "cardId4",
      "extCardId",
      "prdtNo",
    ],
    tests: [
      {
        tag: "All Correct",
        req: {
          nameEnc: "",
          pidNoEnc: "",
          cardIdEnc: "",
          custId: ""
        },
        res: {
          respCode: "00000"
        }
      }
    ]
  },
  {
    title: "Transfer",
    endpoint: "cardTransfer",
    method: HTTP_METHODS.POST,
    attributes: ["custId", "keyIdIN", "keyIdINType", "keyIdOut", "note", "prdtNoOut", "txnAt"],
    respAttributes: [
      "acctIdIn",
      "acctIdOut",
      "acctNmIn",
      "acctNmOut",
      "cardBrhIdIN",
      "cardBrhIdOut",
      "cardBrhNmIN",
      "cardBrhNmOut",
      "cardIdIN4",
      "cardIdOut4",
      "currAvailAtOut",
      "currBalAtOut",
      "endDtOut",
      "intTxnRefId",
      "intTxnSeqId",
      "lockAtOut",
      "note",
      "txnAt",
      "txnDt",
      "txnTm",
    ],

  },
  {
    title: "Request Physical Card for New User",
    endpoint: "physicalCardAdd",
    method: HTTP_METHODS.POST,
    attributes: [],
    respAttributes: [],
    tests: [
      {
        tag: "All Correct",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "00000"
        }
      },
      {
        tag: "Non Physical Brand",
        req: {
          brandId: "0005",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01058"
        }
      },
      {
        tag: "Wrong Brand",
        req: {
          brandId: "9999",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01050"
        }
      },
      {
        tag: "Wrong CustId",
        req: {
          brandId: "0003",
          custId: "wrong",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01300"
        }
      },
      {
        tag: "No CustId",
        req: {
          brandId: "0003",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong Mobile",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "wrong",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01520"
        }
      },
      {
        tag: "No Mobile",
        req: {
          brandId: "0003",
          custId: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong Name",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "",
          nameEnc: "wrong",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "01519"
        }
      },
      {
        tag: "No Name",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "",
          pidNoEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong Pid",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "wrong",
          pidType: ""
        },
        res: {
          respCode: "01521"
        }
      },
      {
        tag: "No Pid",
        req: {
          brandId: "10101",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidType: ""
        },
        res: {
          respCode: "10101"
        }
      },
      {
        tag: "Wrong PidType",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "",
          nameEnc: "",
          pidNoEnc: "",
          pidType: "wrong"
        },
        res: {
          respCode: "01521"
        }
      },
      {
        tag: "No PidType",
        req: {
          brandId: "0003",
          custId: "",
          mobile: "wrong",
          nameEnc: "",
          pidNoEnc: "",
        },
        res: {
          respCode: "10101"
        }
      },
    ]
  },
];

