<template>
  <div class="main-layout">
    <LoadingComponent />
    <SideBarComponent />
    <router-view class="main-layout-content"></router-view>
  </div>
</template>


<script>
import SideBarComponent from "@/components/main/SideBarComponent.vue";
import LoadingComponent from "@/components/main/LoadingComponent.vue";

export default {
  name: "MainLayout",
  components: {
    SideBarComponent,
    LoadingComponent,
  },
};
</script>
<style lang="scss">
@import "@/assets/stylesheets/main/main.scss";
</style>
