<template>
  <div class="side-bar-component">
    <div
      class="logo"
      :style="{ 'background-image': `url('/images/bongloy_logo.png')` }"
    ></div>

    <!-- <div class="menu">
      <div
        :class="`item menu-item ${endpoint.verified ? 'verified' : ''}`"
        v-for="endpoint in endpoints"
        :id="`menu-${endpoint.endpoint}`"
        :key="endpoint.endpoint"
        @click="goToRef(endpoint.endpoint)"
        :title="endpoint.title"
      >
        {{ endpoint.title }}
      </div>
    </div> -->
  </div>
</template>


<script>
export default {
  name: "SideBarComponent",
  computed: {
    // endpoints() {
    //   return this.$store.getters["client/endpoints/filterEndpoints"](
    //     this.$route.meta.include,

    //   );
    // },
  },
  methods: {
    goToRef(id) {
      var element = document.getElementById(id);
      var ctn = document.getElementById("endpoint-scroll-ctn");
      var top = element.offsetTop;
      ctn.scrollTo(0, top);
    },
  },
  mounted() {
    // const ctn = document.getElementById("endpoint-scroll-ctn");
    // const endpointIds = this.endpoints.map((e) => e.endpoint);
    // var done = false;
    // ctn.onscroll = function () {
    //   done = false;
    //   endpointIds.forEach((e) => {
    //     const menuElem = document.getElementById(`menu-${e}`);
    //     if (done) {
    //       menuElem.setAttribute("data-scrolled-passed", false);
    //       return;
    //     }
    //     const elem = document.getElementById(e);
    //     const vpOffset = elem.getBoundingClientRect().top + elem.offsetHeight;
    //     menuElem.setAttribute("data-scrolled-passed", false);
    //     if (vpOffset > 0) {
    //       menuElem.setAttribute("data-scrolled-passed", true);
    //       done = true;
    //     }
    //   });
    // };
  },
};
</script>
<style scoped>
</style>
