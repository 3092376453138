<template>
  <div :class="`loading-component ${isLoadingTest ? 'active' : ''}`">
    <div class="loader inner"></div>
    <div class="loader outer"></div>
  </div>
</template>


<script>

export default {
  name: 'LoadingComponent',
  computed: {
    isLoadingTest() {
      return this.$store.state.main.tests.isLoadingTest;
    },
  },
};
</script>
<style scoped>

</style>
