
const endpoints = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    filterEndpoints: (state, getters, rootState) => () => {
      const attributes = require(`../../resources/${rootState.route.params.version}/attributes`).ATTRIBUTES
      const endpoints = require(`../../resources/${rootState.route.params.version}/endpoints`).ENDPOINTS.sort((a, b) => a.endpoint.localeCompare(b.endpoint))

      return endpoints.map((endpoint) => {
        return {
          ...endpoint,
          tests: endpoint.tests && endpoint.tests.map((test) => {

            return {
              ...test,
              req:
                Object.fromEntries(
                  Object.keys(test.req).map((item) => [
                    item,
                    test.req[item] == "wrong" ?
                      attributes.find(e => e.key == item).wrongValue || "no-wrong-value" :
                      test.req[item] != "" ?
                        test.req[item] :
                        attributes.find(e => e.key == item).value
                  ])
                )
            }
          })
        }
      });
    },
    filterEndpointByKey: (state, getters, rootState) => (key) => {
      console.log('key', key)
      const attributes = require(`../../resources/${rootState.route.params.version}/attributes`).ATTRIBUTES
      const endpoint = require(`../../resources/${rootState.route.params.version}/endpoints`).ENDPOINTS
        .find(e => e.endpoint == key)


      return {
        ...endpoint,
        tests: endpoint.tests && endpoint.tests.map((test) => {
          return {
            ...test,
            req:
              Object.fromEntries(
                Object.keys(test.req).map((item) => [
                  item,
                  test.req[item] == "wrong" ?
                    attributes.find(e => e.key == item).wrongValue || "no-wrong-value" :
                    test.req[item] != "" ?
                      test.req[item] :
                      attributes.find(e => e.key == item).value
                ])
              )
          }
        })
      };
    },
  }
}

export default endpoints


