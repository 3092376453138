import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { sync } from 'vuex-router-sync'
sync(store, router)

import JsonViewer from 'vue-json-viewer'


createApp(App).use(store).use(router).use(JsonViewer).mount('#app')
