import { createRouter, createWebHistory } from 'vue-router'
import EndpointPage from '@/pages/main/endpoint/EndpointPage'
import EndpointSummaryPage from '@/pages/main/endpoint/EndpointSummaryPage'
import MainLayout from '@/layouts/MainLayout'

const routes = [
  {
    path: '/:version/endpoints',
    name: 'main-layout',
    component: MainLayout,
    children: [
      {
        path: 'summary',
        name: 'endpoint-summary-page',
        component: EndpointSummaryPage,
        meta: {
          isSummary: true
        }
      },
      {
        path: ':key',
        name: 'endpoint-page',
        component: EndpointPage,
        meta: {
          showDetail: true
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
